import { Col, Container, Row, Nav, Tab } from "react-bootstrap"
import { ProjectCard } from "./ProjectCard"

import colorSharp2 from "../assets/img/color-sharp2.png";
import projImg11 from "../assets/img/project-img1.png"
import projImg22 from "../assets/img/project-img2.png"
import projImg33 from "../assets/img/project-img3.png"

import projImg1 from "../assets/img/projects/main.png"
import projImg2 from "../assets/img/projects/run.png"
import projImg3 from "../assets/img/projects/pineapple.png"
import projImg4 from "../assets/img/projects/productList.png"
import projImg5 from "../assets/img/projects/webshop.png"
import projImg6 from "../assets/img/projects/marv.png"

import projImg7 from "../assets/img/projects/drums.png"
import projImg8 from "../assets/img/projects/dice.png"
import projImg9 from "../assets/img/projects/simon.png"

import foodapp from "../assets/img/projects/foodapp.png"
import adminpanel from "../assets/img/projects/admin-panel.png"
import Losangeles from "../assets/img/projects/LosangelesMountains.jpeg"


export const Projects = () => {
	const projects = [
		{
			title: "Food App",
			description: 'Design & Development',
			imgUrl: foodapp,
			link: "http://test8.alexanackis.com/",
			linkGitHub: "https://github.com/anackis/Food_App_Form_Scratch/tree/main",
		},
		{
			title: "Free Bank",
			description: 'Design & Development',
			imgUrl: projImg1,
			link: "http://www.test.alexanackis.com/",
			linkGitHub: "https://github.com/anackis/Web_Bank_App_From_Scratch",
		},
		{
			title: "Web Shop",
			description: 'Design & Development',
			imgUrl: projImg5,
			link: "http://test4.alexanackis.com/",
			linkGitHub: "https://github.com/anackis/WebShop",
		},
		{
			title: "Marvel Wiki",
			description: 'Design & Development',
			imgUrl: projImg6,
			link: "http://test2.alexanackis.com/#",
			linkGitHub: "https://github.com/anackis/Marvel-Wiki",
		},
		{
			title: "RunSmart",
			description: 'Design & Development',
			imgUrl: projImg2,
			link: "http://test1.alexanackis.com/#promo",
			linkGitHub: "https://github.com/anackis/ProjectNr2",
		},
		{
			title: "Product List/Add",
			description: 'Design & Development',
			imgUrl: projImg4, 
			link: "http://test7.alexanackis.com/",
			// link: "http://test8.alexanackis.com/",
			linkGitHub: "https://github.com/anackis/Products-List_and_Add-Product-Panel",
		},
		{
			title: "Pineapple",
			description: 'Design & Development',
			imgUrl: projImg3,
			link: "http://test3.alexanackis.com/",
			linkGitHub: "https://github.com/anackis/Junior-Mid-recruiting-task",
		},
		{
			title: "Losangeles Mountains",
			description: 'Design & Development',
			imgUrl: Losangeles,
			link: "http://test5.alexanackis.com/",
			linkGitHub: "https://github.com/anackis/Losangeles_mountains-Junior-Recruiting_Test-",
		},
		{
			title: "Workers Admin Panel",
			description: 'Design & Development',
			imgUrl: adminpanel,
			link: "http://test6.alexanackis.com/",
			linkGitHub: "https://github.com/anackis/Workers-Admin-Panel",
		},
	];

	const projects2 = [
		{
			title: "Web Drums",
			description: 'Design & Development',
			imgUrl: projImg7,
			// link: "http://test6.alexanackis.com/",
			linkGitHub: "https://github.com/anackis/PrjectNr1.4",
		},
		{
			title: "Web Dice Game",
			description: 'Design & Development',
			imgUrl: projImg8,
			// link: "http://test5.alexanackis.com/",
			linkGitHub: "https://github.com/anackis/ProjectNr1.3",
		},
		{
			title: "Web Simon's Game",
			description: 'Design & Development',
			imgUrl: projImg9,
			// link: "http://test7.alexanackis.com/",
			linkGitHub: "https://github.com/anackis/PrjectNr1.5",
		},
	];
	const projects3 = [
		{
			title: "Future Project",
			description: 'Design & Development',
			imgUrl: projImg11,
		},
		{
			title: "Future Project",
			description: 'Design & Development',
			imgUrl: projImg22,
		},
		{
			title: "Future Project",
			description: 'Design & Development',
			imgUrl: projImg33,
		},
	];
	
	
	return (
		<section className="project" id="projects">
			<Container>
				<Row>
					<Col>
						<h2>Projects</h2>
						<p>
						Here is some of my projects. All my projects you can find on my <a className="githubLink" href="https://github.com/anackis?tab=repositories">Github</a> public page.
						</p>
						<Tab.Container id="projects-tabs" defaultActiveKey="first">
							<Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
								{/* <Nav.Item>
									<Nav.Link eventKey="first">Web Sites</Nav.Link>
								</Nav.Item> */}
								{/* <Nav.Item>
									<Nav.Link eventKey="second">Web Games</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey="third">Future Projects</Nav.Link>
								</Nav.Item> */}
							</Nav>
							<Tab.Content>
								<Tab.Pane eventKey="first">
									<Row>
										{
											projects.map((project, index) => {
												return (
													<ProjectCard
														key={index}
														{...project}
													/>
												)
											})
										}
									</Row>
								</Tab.Pane>
								{/* <Tab.Pane eventKey="second">
								<Row>
										{
											projects2.map((project2, index) => {
												return (
													<ProjectCard
														key={index}
														{...project2}
													/>
												)
											})
										}
									</Row>
								</Tab.Pane>
								<Tab.Pane eventKey="third">
								<Row>
										{
											projects3.map((project3, index) => {
												return (
													<ProjectCard
														key={index}
														{...project3}
													/>
												)
											})
										}
									</Row>
								</Tab.Pane> */}
							</Tab.Content>
						</Tab.Container>
					</Col>
				</Row>
			</Container>
			<img className="background-image-right" src={colorSharp2} alt="BgImg"/>
		</section>
	)
	
}