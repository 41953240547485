import {useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap"
import { ArrowRightCircle } from 'react-bootstrap-icons';
// import headerImg from "../assets/img/header-img.svg";
import headerImg from "../assets/img/austr.png";
import Popup from "./Popup";

export const Banner = () => {
	const [loopNum, setLoopNum] = useState(0);
	const [isDeleting, setIsDeleting] = useState(false);
	const toRotate = ["Junior", "Web Developer "];
	const [text, setText] = useState('');
	const [delta, setDelta] = useState(300 - Math.random() * 100);
	const period = 2000;

	useEffect(() => {
		let ticker = setInterval(() => {
			tick();
		},delta )
		return () => { clearInterval(ticker)};
	}, [text])

	const tick = () => {
		let i = loopNum % toRotate.length;
		let fullText = toRotate[i];
		let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);
		setText(updatedText);
		if (isDeleting) {
			setDelta(prevDelta => prevDelta /2)
		}
		if (!isDeleting && updatedText === fullText) {
			setIsDeleting(true);
			setDelta(period);
		} else if (isDeleting && updatedText === '' ) {
			setIsDeleting(false);
			setLoopNum(loopNum + 1);
			setDelta(500);
		}
	}

	const [buttonPopup, setButtonPopup] = useState(false);

	return (
		<section className="banner" id="home">
			<Container>
				<Row className="align-items-center">
					<Col xs={12} md={6} xl={7}>
							<span className="tagline">Welcome to my Portfolio</span>
							<h1>{`I'm `}<span className="wrap">{text}</span></h1>
							<p>My name is Alex. I am 24 years highly motivated and energetic junior Web Developer. I am acquiring
							web development skills for 9+ months. I built over 30 different levels of difficulty
							projects that are public on my GitHub page. </p>
							<p>
              As part of my developing way, I finished 4 online courses on the Udemy platform.
							In addition to courses, I was developing my skills in Free-code
							camp and other platforms where you can level up your skills. During my progress way I  learned, acquired, developed many skills, and got familiar
							with many technologies.
							</p>
							<p>
							Last my working experience was working in an IT company for over 2.5 years where
							I have got skills of effectively self-managing during independent projects, as well as
							collaborating as part of a productive team.
							</p>
							<button className="banner__button" onClick={() => setButtonPopup(true)}>Let's connect <ArrowRightCircle size={25} /> </button>
							<Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
                {/* <h3>My popup</h3> */}
              </Popup>
					</Col>
					<Col className="disappear" xs={12} md={6} xl={5} >
						<img src={headerImg} alt="Header Img"/>
					</Col>
				</Row>
			</Container>
		</section>
	)
}